<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">  

            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->  

                    <!--detail_indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show"/>
                    <!--detail_indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!--MA spec start-->
                    <div class="content-onglets" v-else-if="key == 'detail_MaSpac' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <tr>
                                <td class="bold"><strong> {{$t('TRS_AMM')}} </strong></td>
                                <td> {{$t('ceesp_type_amm_'+data['pbac']['pbac_type_amm'])}} </td>
                            </tr>
                        </table>
                    </div>
                    <!--MA spec end-->
                    
                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!--Purpose of Application start-->
                    <div class="content-onglets" v-else-if="key == 'detail_purpose_of_application' && item.show">
                        <span v-html="data.pbac['purpose_of_application']"></span>
                    </div>
                    <!--Purpose of Application end-->

                    <!--detail_PbacKeydoc start-->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!--PDF end-->

                    <!--Requested listing start-->
                    <div class="content-onglets" v-else-if="key == 'detail_requested_listing' && item.show">
                        <span v-html="data['pbac']['requested_listing']"></span>
                    </div>
                    <!--Requested listing end-->

                    <!--Background start-->
                    <div class="content-onglets" v-else-if="key == 'detail_background' && item.show">
                        <span v-html="data['pbac']['background']"></span>
                    </div>
                    <!--Background end-->

                    <!--Registration Status start-->
                    <div class="content-onglets" v-else-if="key == 'detail_registration_status' && item.show">
                        <span v-html="data['pbac']['registration_status']"></span>
                    </div>
                    <!--Registration Status end-->

                    <!--Summary of re-submission start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_summary_re_submission' && item.show">
                        <span v-html="data['pbac']['summary_re_submission']"></span>
                    </div>
                    <!--Summary of re-submission end-->

                    <!--comparator start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_comparator' && item.show">
                        <div class="table-responsive">
                            <span v-html="data['pbac']['comparator']"></span>  
                        </div>
                        <table class="comparateur2 tb-col2" v-if="data.pbac.pbac_comparator.length > 0">
                            <tbody>
                                <tr>
                                    <th width="75%"> {{$t('comparator')}} </th>
                                    <th width="25%"> {{$t('pbac position')}} </th>
                                </tr>
                                <tr v-for="(item_comparator, key_comparator) in data.pbac.pbac_comparator" :key="'key_comparator_'+key_comparator">
                                    <td class="text-left"> {{item_comparator['comparator'] ? item_comparator['comparator'] : '-' }}</td>
                                    <td> {{item_comparator['accepted'] ? item_comparator['accepted'] : '-'}} </td>
                                </tr> 
                            </tbody>
                        </table> 
                    </div> 
                    <!--comparator end-->   

                    <!-- detail_consumer_sponsor start -->
                    <div class="content-onglets" v-else-if="key == 'detail_consumer_sponsor' && item.show">
                        <span v-html="data['pbac']['consumer_sponsor']"></span>
                    </div>
                    <!-- detail_consumer_sponsor end -->

                    <!-- detail_clinical_trials2 start -->
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_trials2' && item.show">
                        <div v-if="data['pbac']['clinical_trials']">
                            <span v-html="data['pbac']['clinical_trials']"></span>
                        </div>             
                        <div v-if="data['pbac']['comparative_effectiveness']">
                            <strong> {{$t('comparative_effectiveness')}} </strong>
                            <span v-html="data['pbac']['comparative_effectiveness']"></span>
                        </div> 
                        <div v-if="data['pbac']['comparative_harms']">
                            <strong> {{$t('comparative_harms')}} </strong>
                            <span v-html="data['pbac']['comparative_harms']"></span>  
                        </div>               
                        <div v-if="data['pbac']['benefits_harms']">   
                            <strong> {{$t('benefits_harms')}} </strong>
                            <span v-html="data['pbac']['benefits_harms']"></span>  
                        </div>
                        <div v-if="data['pbac']['results_of_trials']">
                            <strong> {{$t('results_of_trials')}} </strong>
                            <span v-html="data['pbac']['results_of_trials']"></span>         
                        </div>    
                    </div> 
                    <!-- detail_clinical_trials2 end -->

                    <!-- require_once 'sub_detail/clinical_trial.ctp'  -->

                    <!-- detail_pricing_considerations start -->
                    <div class="content-onglets" v-else-if="key == 'detail_pricing_considerations' && item.show">
                        <span v-html="data['pbac']['pricing_consideration']"></span>
                    </div>
                    <!-- detail_pricing_considerations end -->

                    <!-- detail_clinical_claim start -->
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_claim' && item.show">
                        <span v-html="data['pbac']['clinical_claim']"></span>
                    </div>
                    <!-- detail_clinical_claim end -->

                    <!-- detail_economic_analysis start -->
                    <div class="content-onglets" v-else-if="key == 'detail_economic_analysis' && item.show">
                        <span v-html="data['pbac']['economic_analysis']"></span>
                    </div>
                    <!-- detail_economic_analysis end -->

                    <!--Link effectiveness_evidences start-->
                    <div class="content-onglets" v-else-if="key == 'detail_PbacEffectivenessEvidence' && item.show">
                        <div class="table-responsive" >      
                            <table class="comparateur2" v-if="data['pbac']['pbac_effectiveness_evidence'].length > 0">                 
                                <thead>    
                                    <tr>
                                        <th width="10%" class="first" >Model type</th> 
                                        <th width="10%" >ICER comparator</th>
                                        <th width="10%" >ICER type</th>
                                        <th width="10%" >ICER basecase (AUD)</th>
                                        <th width="10%" >ICER lower (AUD)</th>
                                        <th width="10%" >ICER upper (AUD)</th>
                                        <th width="10%" >ICER probability</th> 
                                        <th width="30%" class="last">PBAC comment on ICER</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item_pbac_effective_evidence, key_pbac_effective_evidence) in data['pbac']['pbac_effectiveness_evidence']" :key="'key_pbac_effective_evidence_'+key_pbac_effective_evidence"> 
                                        <td> {{item_pbac_effective_evidence['icer_model_type'] ? item_pbac_effective_evidence['icer_model_type'] : '-'}} </td> 
                                        <td> {{item_pbac_effective_evidence['icer_comparator'] ? item_pbac_effective_evidence['icer_comparator'] : '-'}} </td>
                                        <td> {{item_pbac_effective_evidence['icer_type'] ? item_pbac_effective_evidence['icer_type'] : '-'}} </td>
                                        <td> {{item_pbac_effective_evidence['icer_basecase_text'] ? item_pbac_effective_evidence['icer_basecase_text'] : '-'}} </td>
                                        <td> {{item_pbac_effective_evidence['icer_lower_text'] ? item_pbac_effective_evidence['icer_lower_text'] : '-'}} </td>
                                        <td> {{item_pbac_effective_evidence['icer_upper_text'] ? item_pbac_effective_evidence['icer_upper_text'] : '-'}} </td>
                                        <td> {{item_pbac_effective_evidence['icer_probability'] ? item_pbac_effective_evidence['icer_probability'] : '-'}} </td>
                                        <td class="textJustify">
                                            <div class="expandable textJustify" v-if="item_pbac_effective_evidence['icer_pbac_comment']"> 
                                                <span v-html="item_pbac_effective_evidence['icer_pbac_comment'] ? item_pbac_effective_evidence['icer_pbac_comment'] : '-'"></span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                  
                            <a class="btn btn-mini btn-warning" style="color: #fff;" target="_blank" rel="noopener" :href="FULL_BASE_URL + '/pdf/view_pdf_popup/'+ $route.params.id +'/' + $route.params.agency" > Full analysis </a>
                        
                        </div>
                    </div>  
                    <!--Link effectiveness_evidences end-->

                    <!-- detail_estimated_pbs_usage_financial_implications start -->
                    <div class="content-onglets" v-else-if="key == 'detail_estimated_pbs_usage_financial_implications' && item.show">
                        <span v-html="data['pbac']['estimated_pbs_usage_financial_implications']"></span>
                    </div>
                    <!-- detail_estimated_pbs_usage_financial_implications end -->

                    <!-- detail_financial_management_risk_sharing_arrangements start -->
                    <div class="content-onglets" v-else-if="key == 'detail_financial_management_risk_sharing_arrangements' && item.show">                    
                        <span v-html="data['pbac']['financial_management_risk_sharing_arrangements']"></span>
                    </div>
                    <!-- detail_financial_management_risk_sharing_arrangements end -->

                    <!-- detail_quality_use_of_medicines start -->
                    <div class="content-onglets" v-else-if="key == 'detail_quality_use_of_medicines' && item.show">                    
                        <span v-html="data['pbac']['quality_use_of_medicines']"></span>
                    </div>
                    <!-- detail_quality_use_of_medicines end -->

                    <!--Context for Decision start-->
                    <div class="content-onglets" v-else-if="key == 'detail_context_for_decision' && item.show">                    
                        <span v-html="data['pbac']['context_for_decision']"></span>
                    </div>
                    <!--Context for Decision end-->

                    <!--Sponsor’s Comment start-->
                    <div class="content-onglets" v-else-if="key == 'detail_sponsor_comment' && item.show">                    
                        <span v-html="data['pbac']['sponsor_comment']"></span>
                    </div>
                    <!--Sponsor’s Comment end-->

                    <!--Link PrixAustralie start-->
                    <!--Link PrixAustralie end-->

                    <!--PBAC Key conclusion start-->
                    <div class="content-onglets" v-else-if="key == 'detail_key_conclusion' && item.show">
                        <span v-html="data['pbac']['key_conclusion']"></span> 
                    </div>
                    <!--PBAC Key conclusion end-->

                    <!--Summary of re-submission start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_place_proposed_therapy' && item.show">
                        <span v-html="data['pbac']['clinical_place_proposed_therapy']"></span>
                    </div>
                    <!--Summary of re-submission end--> 

                    <!--PBAC Outcome start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_pbac_outcome' && item.show">
                        <span v-html="data['pbac']['pbac_outcome']"></span>
                    </div>
                    <!--PBAC Outcome end--> 

                    <!--PBAC consideration of the evidence start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_consideration_of_evidence' && item.show">
                        <span v-html="data['pbac']['consideration_of_evidence']"></span>
                    </div>
                    <!--PBAC consideration of the evidence end-->

                    <!--detail_drug_cost_patient_course start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_drug_cost_patient_course' && item.show">
                        <span v-html="data['pbac']['drug_cost_patient_course']"></span>
                    </div>
                    <!--detail_drug_cost_patient_course end-->

                    <!--Recommendation start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_recommendation' && item.show">
                        <span v-html="data['pbac']['recommendation']"></span>
                    </div>
                    <!--Recommendation end-->

                    <!--recommended_listing start-->   
                    <div class="content-onglets" v-else-if="key == 'detail_recommended_listing' && item.show">
                        <span v-html="data['pbac']['recommended_listing']"></span>
                    </div>
                    <!--recommended_listing end-->

                    <!--recommendation_reasons start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_recommendation_reasons' && item.show">
                        <span v-html="data['pbac']['recommendation_reasons']"></span>
                    </div>
                    <!--recommendation_reasons end-->

                    <!--Listing Requested and PBAC’s View start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_listing_requested_pbac_view' && item.show">
                        <span v-html="data['pbac']['listing_requested_pbac_view']"></span>
                    </div>
                    <!--Listing Requested and PBAC’s View start--> 

                    <!--EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!--EssaisClinique end-->   

                    <!-- detail_EvaluationEconomic  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.pbac.evaluation_economic" />

                    <!-- detail_EconomicEvaluation -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import {check_detail_conclusion, key_documents} from '../../../utils'

export default{
    name: 'pbac',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        EvaluationEconomic,
        Conclusion,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        InformationGeneral,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_MaSpac : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_purpose_of_application : {'title' : 'purpose_of_application', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_requested_listing : {'title' : 'requested_listing', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_background : {'title' : 'background', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_registration_status : {'title' : 'registration_status', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_summary_re_submission : {'title' : 'summary_re_submission', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_comparator : {'title' : 'comparator', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_consumer_sponsor : {'title' : 'consumer_sponsor', 'show' : true, 'enable' : false, 'orange_text':false },             
                // detail_clinical_trials2 : {'title' : 'clinical_trials', 'show' : true, 'enable' : false, 'orange_text':false },             
                // detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_pricing_considerations : {'title' : 'Pricing considerations', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_clinical_claim : {'title' : 'clinical_claim', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_economic_analysis : {'title' : 'economic_analysis', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_PbacEffectivenessEvidence : {'title' : 'Cost effectiveness Evidence', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_estimated_pbs_usage_financial_implications : {'title' : 'estimated_pbs_usage_financial_implications', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_financial_management_risk_sharing_arrangements : {'title' : 'financial_management_risk_sharing_arrangements', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_quality_use_of_medicines : {'title' : 'quality_use_of_medicines', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_context_for_decision : {'title' : 'context_for_decision', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_sponsor_comment : {'title' : 'sponsor_comment', 'show' : true, 'enable' : false, 'orange_text':false },             
                // PrixAustralie
                detail_key_conclusion : {'title' : 'PBAC Key Conclusion', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_clinical_place_proposed_therapy : {'title' : 'clinical_place_proposed_therapy', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_pbac_outcome : {'title' : 'pbac_outcome', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_consideration_of_evidence : {'title' : 'pbac_consideration_of_the_evidence', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_drug_cost_patient_course : {'title' : 'drug_cost_patient_course', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_recommendation : {'title' : 'recommendation', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_recommended_listing : {'title' : 'recommended_listing', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_recommendation_reasons : {'title' : 'recommendation_reasons', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_listing_requested_pbac_view : {'title' : 'listing_requested_pbac_view', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },             
            },
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.pbac){
            
            if(this.data['ma_date'])
            { 
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.data['indication_en'])
            { 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  
            { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.pbac['pbac_type_amm'])
            { 
                this.list_check_box['detail_MaSpac'].enable = true 
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }

            if(this.data.pbac['purpose_of_application'])
            { 
                this.list_check_box['detail_purpose_of_application'].enable = true 
            }              
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }   
            if(this.data.pbac['requested_listing'])
            { 
                this.list_check_box['detail_requested_listing'].enable = true 
            }
            if(this.data.pbac['background'])
            { 
                this.list_check_box['detail_background'].enable = true 
            }
            if(this.data.pbac['registration_status'])
            { 
                this.list_check_box['detail_registration_status'].enable = true 
            }
            if(this.data.pbac['summary_re_submission'])
            { 
                this.list_check_box['detail_summary_re_submission'].enable = true 
            }
            if(this.data.pbac['comparator'])
            { 
                this.list_check_box['detail_comparator'].enable = true 
            }
            if(this.data.pbac['consumer_sponsor'])
            { 
                this.list_check_box['detail_consumer_sponsor'].enable = true 
            }
            // if(this.data.pbac['clinical_trials'] || this.data.pbac['comparative_effectiveness'] || this.data.pbac['comparative_harms'] ||
            //     this.data.pbac['benefits_harms'] || this.data.pbac['results_of_trials'])
            // { 
            //     this.list_check_box['detail_clinical_trials2'].enable = true 
            // }
            // if(this.data.data_links['EvaluationEssaisCliniqueAspiration'])
            // { 
            //     this.list_check_box['detail_clinical_trials'].enable = true 
            // }
            if(this.data.pbac['pricing_consideration'])
            { 
                this.list_check_box['detail_pricing_considerations'].enable = true 
            }
            if(this.data.pbac['clinical_claim'])
            { 
                this.list_check_box['detail_clinical_claim'].enable = true 
            }
            if(this.data.pbac['economic_analysis'])
            { 
                this.list_check_box['detail_economic_analysis'].enable = true 
            }
            if(this.data.pbac['pbac_effectiveness_evidence'].length > 0 
            // this.data.pbac['total_cost'] ||
            // this.data.pbac['number_patient'] ||
            // this.data.pbac['budget_impac_1_year'] ||
            // this.data.pbac['budget_impac_5_year'] ||                
            // this.data.pbac['model_type'] || 
            // this.data.pbac['type_analyse'] || 
            // this.data.pbac['updating'] || 
            // this.data.pbac['active_comparator'] ||                 
            // this.data.pbac['compatator_relevancy'] || 
            // this.data.pbac['poppulation_analyse'] ||                 
            // this.data.pbac['index_treatment'] || 
            // this.data.pbac['utility_data'] || 
            // this.data.pbac['cost_type'] 
                )                
            { 
                this.list_check_box['detail_PbacEffectivenessEvidence'].enable = true 
            }                
            if(this.data.pbac['estimated_pbs_usage_financial_implications'])
            { 
                this.list_check_box['detail_estimated_pbs_usage_financial_implications'].enable = true 
            }
            if(this.data.pbac['financial_management_risk_sharing_arrangements'])
            { 
                this.list_check_box['detail_financial_management_risk_sharing_arrangements'].enable = true 
            }
            if(this.data.pbac['quality_use_of_medicines'])
            { 
                this.list_check_box['detail_quality_use_of_medicines'].enable = true 
            }
            if(this.data.pbac['context_for_decision'])
            { 
                this.list_check_box['detail_context_for_decision'].enable = true 
            }
            if(this.data.pbac['sponsor_comment'])
            { 
                this.list_check_box['detail_sponsor_comment'].enable = true 
            }
            // PrixAustralie
            if(this.data.pbac['key_conclusion'])
            { 
                this.list_check_box['detail_key_conclusion'].enable = true 
            }
            if(this.data.pbac['clinical_place_proposed_therapy'])
            { 
                this.list_check_box['detail_clinical_place_proposed_therapy'].enable = true 
            }
            if(this.data.pbac['pbac_outcome'])
            { 
                this.list_check_box['detail_pbac_outcome'].enable = true 
            }
            if(this.data.pbac['consideration_of_evidence'])
            { 
                this.list_check_box['detail_consideration_of_evidence'].enable = true 
            }
            if(this.data.pbac['drug_cost_patient_course'])
            { 
                this.list_check_box['detail_drug_cost_patient_course'].enable = true 
            }
            if(this.data.pbac['recommendation'])
            { 
                this.list_check_box['detail_recommendation'].enable = true 
            }
            if(this.data.pbac['recommended_listing'])
            { 
                this.list_check_box['detail_recommended_listing'].enable = true 
            }
            if(this.data.pbac['recommendation_reasons'])
            { 
                this.list_check_box['detail_recommendation_reasons'].enable = true 
            }
            if(this.data.pbac['listing_requested_pbac_view'])
            { 
                this.list_check_box['detail_listing_requested_pbac_view'].enable = true 
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if(this.data.pbac.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            }
        }
    },
    methods : {
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return process.env.VUE_APP_URL_PRISMACCESS
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
    },
}

</script>

<style scoped>
</style>